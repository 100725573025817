<template>
  <div class="leader-concern-panel">
    <van-tabs v-model="activeName" @click="onClick" border sticky :swipeable=false>
      <van-tab name="01" style="overflow-y: auto;background-color: white">
        <div slot="title">
          回访到期
          <span v-if="circle1 != 0" class="circle">+{{ circle1 }}</span>
        </div>
        <van-row>
          <van-col span="5" style="margin-top: 20px;padding-left: 10px">
            <i @click="showGrpFzr()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">筛选</i>
            <i @click="showGrpFzr()" :class="`el-icon-${!isAll ? 'arrow-down' : 'arrow-up'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
          </van-col>
          <van-search v-model="selectFzrValue" placeholder="请输入走访名称" @cancel="cancel('01')" show-action/>
        </van-row>
        <div v-if="this.list && this.list.length>=1"
             :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
          <info-card :data="this.list" style="margin-bottom: 50px" @cardClickCallBack="cardClickCallBack"/>
        </div>
        <div v-else style="background-color: #FFFFFF">
          <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
        </div>
      </van-tab>
      <van-tab name="05" style="overflow-y: auto; height: 100%;background-color: white">
        <div slot="title">
          延期说明
          <span v-if="circle2 != 0" class="circle">+{{ circle2 }}</span>
        </div>
        <van-row>
          <van-col span="5" style="margin-top: 20px;padding-left: 10px">
            <i @click="showGrpFzr()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">筛选</i>
            <i @click="showGrpFzr()" :class="`el-icon-${!isAll ? 'arrow-down' : 'arrow-up'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
          </van-col>
          <van-search v-model="selectFzrValue" placeholder="请输入走访名称" @cancel="cancel('05')" show-action/>
        </van-row>
        <div v-if="this.list && this.list.length>=1"
             :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
          <info-card :data="this.list" style="margin-bottom: 50px" @cardClickCallBack="cardClickCallBack"/>
        </div>
        <div v-else style="background-color: #FFFFFF">
          <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
        </div>
      </van-tab>
      <van-tab name="02" style="overflow-y: auto;;background-color: white">
        <div slot="title">
          复盘到期
          <span v-if="circle3 != 0" class="circle">+{{ circle3 }}</span>
        </div>
        <van-row>
          <van-col span="5" style="margin-top: 20px;padding-left: 10px">
            <i @click="showGrpFzr()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">筛选</i>
            <i @click="showGrpFzr()" :class="`el-icon-${!isAll ? 'arrow-down' : 'arrow-up'}`"
               :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
          </van-col>
          <van-search v-model="selectFzrValue" placeholder="请输入走访名称" @cancel="cancel('02')" show-action/>
        </van-row>
        <div v-if="this.list && this.list.length>=1"
             :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
          <info-card :data="this.list" style="margin-bottom: 50px" @cardClickCallBack="cardClickCallBack"/>
        </div>
        <div v-else style="background-color: #FFFFFF">
          <van-empty :image="require('@/assets/空.jpg')" description="没有数据！"/>
        </div>
      </van-tab>
    </van-tabs>
    <fzr-card :data="this.columns" ref="grpFzrCard" @group="grp(arguments)"></fzr-card>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import InfoCard from "@/components/Card/InfoCard";
import FzrCard from "@/components/Card/FzrCard";
import {Toast} from "vant";

export default {
  name: "LeaderConcernPanel",
  components: {CrmTabBar, InfoCard, FzrCard},
  data() {
    return {
      activeName: '01',
      clearData: '',
      circle1: 0,
      circle2: 0,
      circle3: 0,
      isAll: false,
      list: [],
      freshHeight: 0,
      selectFzrValue: '',
      showPopup: false,
      fzr: '',
      //组办下拉列表数据源
      columns: [
        {
          values: '',
          id: 'R00311',
          className: 'column1',
          text: '燕钢项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17039',
              className: 'column1',
              text: '李鹏飞',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12005',
              className: 'column1',
              text: '李阳',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13117',
              className: 'column1',
              text: '王立永',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            },
          ]
        },
        {
          values: '',
          id: 'R00258',
          className: 'column2',
          defaultIndex: 0,
          text: '散-总经办',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '05001',
              className: 'column2',
              text: '赵永鹏',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '04008',
              className: 'column2',
              text: '张鸿凯',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00030',
          className: 'column3',
          defaultIndex: 0,
          text: '散-现场组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '12066',
              className: 'column3',
              text: '张强',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00318',
          className: 'column4',
          defaultIndex: 0,
          text: '钢铁华南项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '21196',
              className: 'column4',
              text: '余蜀东',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00202',
          className: 'column5',
          defaultIndex: 0,
          text: '钢铁东北组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18198',
              className: 'column5',
              text: '王泽民',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '17032',
              className: 'column5',
              text: '马乾博',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '16166',
              className: 'column5',
              text: '孙男',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '13006',
              className: 'column5',
              text: '迟海祥',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '21014',
              className: 'column5',
              text: '陈东宝',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '12007',
              className: 'column5',
              text: '马兆磊',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18061',
              className: 'column5',
              text: '白也',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00301',
          className: 'column6',
          defaultIndex: 0,
          text: '钢煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '17223',
              className: 'column6',
              text: '高龙',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00302',
          className: 'column7',
          defaultIndex: 0,
          text: '电煤项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16086',
              className: 'column7',
              text: '王帅',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00080',
          className: 'column8',
          defaultIndex: 0,
          text: '船管租船组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '16034',
              className: 'column8',
              text: '刘小恺',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00307',
          className: 'column9',
          defaultIndex: 0,
          text: '长协项目组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '13072',
              className: 'column9',
              text: '张航',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '18215',
              className: 'column9',
              text: '翟成基',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
        {
          values: '',
          id: 'R00170',
          className: 'column10',
          defaultIndex: 0,
          text: '(散)粮食组',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          use: false,
          users: [
            {
              values: '',
              pid: '18005',
              className: 'column10',
              text: '许小庆',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }, {
              values: '',
              pid: '14018',
              className: 'column10',
              text: '王杨',
              color: '#4DB8FF',
              backColor: '#EAF4FF',
              use: false,
            }
          ]
        },
      ],
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init(this.activeName)
  },
  watch: {
    //监听公司名头
    'selectFzrValue': function () {
      this.list = [];
      this.init(this.activeName)
    },
    'fzr': function () {
      this.list = [];
      this.init(this.activeName)
    }
  },
  destroyed() {
    //只有领导能够更新状态
    if (this.$store.getters.userId == '05001') {
      this.$ajax.post('/api/ajax/cusPush/updateBulkCrmClearPushConcernInfo.json',
          {titleId: this.clearData.substring(0, this.clearData.lastIndexOf(','))}).then(res => {
      }).catch(err => {

      })
    }
  },
  methods: {
    onClick(name, title) {
      this.selectFzrValue = ''
      this.fzr = ''
      this.init(name)
    },
    init(name) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.$ajax.post('/api/ajax/cusPush/getPushConcernInfo.json',
          {
            pushType: name,
            // pushPerson: this.$store.getters.userId === '05001' || this.$store.getters.userId === '04002' || this.$store.getters.userId === '19060' ? this.fzr : this.$store.getters.userId,
            pushPerson: this.fzr === '' ? this.$store.getters.userId : this.fzr,
            fzr: this.fzr,
            titleName: this.selectFzrValue,
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          this.list = res.data
        }
        if (res.data.length > 0) {
          Toast.success('加载成功');
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].isRead == 'N') {
              this.clearData += "'" + res.data[i].titleId + "',"
            }
          }
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        Toast.fail('加载失败');
        console.log(err)
        this.$message.error('查询失败！');
      })
      this.$ajax.post('/api/ajax/cusPush/getPushCountInfoDetail.json',
          {
            pushPerson: this.$store.getters.userId === '05001' || this.$store.getters.userId === '04002' ? this.fzr : this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          this.circle1 = res.data[0].count
          this.circle2 = res.data[0].count1
          this.circle3 = res.data[0].count2
        }
      }).catch(err => {
      })
    },
    cancel(index) {
      this.selectFzrValue = ''
      this.fzr = ''
      this.init(this.activeName)
    },
    showGrpFzr() {
      this.$refs.grpFzrCard.showPopup = true
      this.$refs.grpFzrCard.pickGrp = 'null'
      this.$refs.grpFzrCard.pickcFzr = 'null'
      this.showPopup = true
    },
    zcr(msg) {
      // data为子页面的传过来的数据
      console.log(msg[0], msg[1], msg[2])
      this.pickZcr = msg[2] != 'null' ? msg[2] : ''
      this.init(this.activeName)
    },
    grp(msg) {
      // data为子页面的传过来的数据
      if (msg[1] != 'null') {
        console.log(msg[1])
        this.fzr = msg[1]
      } else {
        this.fzr = ''
      }
      this.init(this.activeName)
    },
    cardClickCallBack(item) {
      console.log('customer', item)
      if (this.$store.getters.userId == '05001' || this.$store.getters.userId == '19060' || this.$store.getters.userId == '12064' || this.$store.getters.userId == '18280' || this.$store.getters.userId == '04002') {
        this.$router.push({path: item.to, query: {}})
      }
    }
  }
}
</script>

<style scoped>

</style>
<style>
.leader-concern-panel .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}

.leader-concern-panel .circle {
  border-radius: 100%;
  background-color: red;
  padding: 2px;
}
</style>